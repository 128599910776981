import {
  faCheck,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { ReactElement } from 'react';
import Icon from '~/features/common/components/icons/Icon/index';

const WarningMessage = ({
  children,
  classNames,
  condensed,
  iconSize,
  isFullPageError,
  level = 'red',
  testid,
  text,
  textSize,
}: {
  children?: ReactElement;
  classNames?: string;
  condensed?: boolean;
  iconSize?:
    | 'xs'
    | 'lg'
    | 'sm'
    | '1x'
    | '2x'
    | '3x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x';
  isFullPageError?: boolean;
  level?: 'red' | 'yellow' | 'neutral' | 'success';
  testid?: string;
  text?: string;
  textSize?:
    | 'text-2xs'
    | 'text-xs'
    | 'text-sm'
    | 'text-md'
    | 'text-lg'
    | 'text-xl'
    | 'text-2xl';
}) => {
  const levelClasses = {
    red: 'bg-red-300/10 p-3 text-red-600 border border-red-300 dark:border-red-600 dark:text-red-400',
    yellow:
      'bg-yellow-300/10 text-yellow-600 border border-yellow-300 dark:text-yellow-300',
    neutral:
      'bg-blue-300/10 text-blue-900 border border-blue-300 dark:border-blue-500 dark:text-blue-200',
    success:
      'bg-green-300/10 text-green-900 border border-green-300 dark:border-green-500 dark:text-green-200',
  };

  return (
    <div className={clsx(isFullPageError && 'h-full w-full', classNames)}>
      <div
        className={clsx(
          isFullPageError &&
            'absolute bottom-0 left-0 right-0 top-0 z-[999] h-full w-full',
          !isFullPageError && 'flex justify-center'
        )}
      >
        <div
          className={clsx(
            'justify-center rounded',
            levelClasses[level],
            isFullPageError && 'h-full',
            condensed ? 'px-1 py-1' : ' p-3'
          )}
        >
          <div
            className={clsx(
              isFullPageError && 'justify-center',
              'flex h-full items-center text-center'
            )}
            data-testid={testid}
          >
            <Icon
              icon={
                level === 'neutral'
                  ? faInfoCircle
                  : level === 'success'
                    ? faCheck
                    : faExclamationTriangle
              }
              size={iconSize}
              className="mr-2"
            />
            {text && <div className={textSize}>{text}</div>}

            {!text && !children && (
              <div className={textSize}>An unexpected error occurred.</div>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WarningMessage;
